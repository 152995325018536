import React, {useEffect, useState, useRef} from "react";

import WhepPlayer from "./WhepPlayer.jsx";


function Players({players, settings, eventId, currentStyles, setCurrentStyles}) {
  const containerRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
        enterFullscreen();
    } else {
        exitFullscreen();
    }
};

const enterFullscreen = () => {
    if (!isFullscreen) {
        if (containerRef.current.requestFullscreen) {
            containerRef.current.requestFullscreen();
        } else if (containerRef.current.webkitRequestFullscreen) { /* Safari */
            containerRef.current.webkitRequestFullscreen();
        } else if (containerRef.current.msRequestFullscreen) { /* IE11 */
            containerRef.current.msRequestFullscreen();
        }
        setIsFullscreen(true);
    }
};

const exitFullscreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
    }
    setIsFullscreen(false);
};

useEffect(() => {
    const exitHandler = () => {
        if (!document.fullscreenElement) {
            setIsFullscreen(false);
        }
    };

    document.addEventListener('fullscreenchange', exitHandler);
    return () => document.removeEventListener('fullscreenchange', exitHandler);
}, []);

  const styles = [{},{transform: "rotate(90deg) scale(55%)"},{transform: "rotate(180deg)"},{transform: "rotate(270deg) scale(55%)"}];
  
  
  if (!currentStyles) {
    currentStyles = {};
    for (let player of players) currentStyles[player.id] = 0;
  }

  if (!players || !settings) return null;
  const playerPari = players.length % 2 === 0;
  return (
    <div className={isFullscreen ? "fullscreen-container-players relative" : "relative"}  ref={containerRef}>
      <div className="full-screen-toggle" onClick={toggleFullscreen}>
      {isFullscreen ? <span><i className="fas fa-compress ml-3"/> Exit</span> : <span><i className="fas fa-expand ml-3"/> Multiview</span>}
      </div>
      <div className="row video-container-div prevent-select  fullscreen-fixed-frame-players">
      {players.map((player, key) => {
        const streamName = `${eventId}-${player.id}`;
        console.log(`Stream NAME: ${player.name} - Stream CODE: ${streamName}`);
        let style = 0;
        if (currentStyles && currentStyles[player.id]) style = currentStyles[player.id];

        let playerSection = <>
        <div className="col-12 mt-2 mb-1">{player.name} <span className="rotate-btn" onClick={()=>{
          let newStyles = {...currentStyles};
          newStyles[player.id] = style + 1;
                  if (newStyles[player.id] > 3) newStyles[player.id] = 0;
                  localStorage.setItem(eventId + '-rotations', JSON.stringify(newStyles));
                  setCurrentStyles(newStyles);
                }}><i className="fas fa-sync ml-3"></i> rotate</span></div>
                <div className="video-container">
                <WhepPlayer url={streamName} style={styles[style]}/>
                </div>
        </>;

        if (players.length === 1)
          return (
            <>
              <div className="col-md-1" key={key + 100}></div>
              <div className="col-md-10" key={key}>
              {playerSection}
              </div>
              <div className="col-md-1" key={key + 200}></div>
            </>
          );

        if (!playerPari && key === 0)
          return (
            <>
              <div className="col-md-3" key={key + 100}></div>
              <div className="col-md-6" key={key}>
              {playerSection}
              </div>
              <div className="col-md-3" key={key + 200}></div>
            </>
          );

        return (
          <div className="col-md-6" key={key}>
            {playerSection}
          </div>
        );
      })}
    </div>
    </div>
  );
}
export default Players;
