import React, { useState} from "react";
import Chat from "./Chat.jsx";
import Players from "./Players.jsx";

function Main(parent) {
  let [currentStyles, setCurrentStyles] = useState(localStorage.getItem(parent.state.id + '-rotations'));

  if (
    !parent.state.players ||
    !parent.state.playerSettings ||
    !parent.state.players[0]
  )
    return null;
  return (
    <React.Fragment>
      <div
        className={
          parent.state.players && parent.state.players.length === 2
            ? "container double-player"
            : "container"
        }
      >
        <div className="d-flex flex-column flex-lg-row align-items-center px-3 pb-0 px-lg-4">
          <div className="col-auto">
            <img
              src={parent.state.logo}
              alt="virtualset logo"
              className="logo"
            />
          </div>
          <div className="col"></div>
          <div className="col-auto">
            <div className="logout" id="logout">
            <i className="fas fa-user"></i> {parent.state.userName}{" "}
            <i className="fas fa-calendar ml-3"></i> {parent.state.liveName}{" "}
              <i
                className="fas fa-sign-out-alt ml-3 pointer"
                onClick={() => {
                  parent.logout();
                }}
              ></i>
            </div>
          </div>
        </div>
        <Players
          players={parent.state.players.filter(e=>!e.hidden)}
          settings={parent.state.playerSettings}
          eventId={parent.state.id}
          currentStyles={currentStyles}
          setCurrentStyles={setCurrentStyles}
        />
        {parent.state.hasChat ? (
          <Chat
            channel={parent.state.channel}
            chatExpire={parent.state.chatExpire}
            hasAudio={parent.state.hasAudio}
            hasChat={parent.state.hasChat}
            userId={parent.state.userId}
            userName={parent.state.userName}
            muted={parent.state.mode === "muted"}
            messages={parent.state.messages}
            sendMessage={(m) => {
              parent.sendMessage(m);
            }}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
}


export default Main;
