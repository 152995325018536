// react component that get an url and render the webrtc whep player
import { useEffect, useRef, useState } from "react";
let count = {};

// a polyfill for it would be:
AbortSignal.timeout ??= function timeout(ms) {
    const ctrl = new AbortController()
    setTimeout(() => ctrl.abort(), ms)
    return ctrl.signal
  }

const WhepPlayer = ({ url, style }) => {
  const videoRef = useRef(null);
  const whepClient = useRef(null);
  const interaction = useRef(null);
  const [isStreamAvailable, setStreamAvailable] = useState(false);
  useEffect(() => {
    // detecting if the stream is available  and loading the video if it is
    const fetchStream = async () => {
      if (!count[url]) count[url] = 4;
        const urlPreview = `https://dio22.streamset.pro/live/${url}/playlist.m3u8`;
    fetch(urlPreview, { method: "HEAD",signal: AbortSignal.timeout(1200) })
      .then((res) => {
        if (res.ok) {
            count[url]++;
          if (count[url] > 4) {
            setStreamAvailable(true);
          }
        } else {
          count[url] = 1;
          if (whepClient.current) whepClient.current.destroy();
          
          clearInterval(interaction.current);
          setStreamAvailable(false);
        }
        
        console.log("URL: ",url, "Status:", count[url], res.ok ? "Stream available" : "Stream not available");
    });
    };
    let interval = setInterval(fetchStream, 2000);
    fetchStream();
    return () => {
      clearInterval(interval);
      console.log("unmounting component...");
    }
  }, [url,isStreamAvailable]);

  useEffect(() => {
    if (videoRef.current && isStreamAvailable) {
      whepClient.current = new window.WebRTCPlayer({
        video: videoRef.current,
        type: "whep",
      });
      window.whepClient = whepClient.current;
      whepClient.current.load(
        new URL(`https://dio22.streamset.pro/live/${url}/whep.stream`), {timeoutThreshold: 1200}
      );
      videoRef.current.muted = true;
      clearInterval(interaction.current);
      interaction.current = setInterval(() => {
        if (videoRef.current.paused) videoRef.current.play();

        if (navigator.userActivation.isActive) {
            videoRef.current.muted = false;
            if (videoRef.current.paused) videoRef.current.play();
          clearInterval(interaction.current);
        }
      }, 2000);

      whepClient.current.on("error", (err) => {
        console.log("Error:", err);
        setStreamAvailable(false);
      });

        whepClient.current.on("ended", () => {
            console.log("Stream ended");
            setStreamAvailable(false);
        });
    }
    return () => {
        if (whepClient.current) {
            whepClient.current.destroy();
          }
      if (interaction.current) {
        clearInterval(interaction.current);
      }
    };
  }, [url, isStreamAvailable]);

if (!isStreamAvailable) return <div className="video-placeholder">waiting for video...</div>;
  return (
    <video
      ref={videoRef}
      style={style}
      controls
      autoPlay
      muted
      playsInline
    ></video>
  );
};

export default WhepPlayer;
