import React from 'react';
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {jwtDecode} from 'jwt-decode';
import './App.css';
import './Player.jsx';
import Login from './Login.jsx';
import Main from './Main.jsx';
// import Mobile from './Mobile.jsx';
// import {
//   isMobileOnly,
//   // isAndroid,
//   isIOS,
//   // isMobile,
//   // isTablet,
//   // MobileOnlyView
// } from "react-device-detect";

const baseUrl = 'https://client-api.streamset.pro/v2';
class App extends React.Component {

  ws = null;

  prevLogin = "new";

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      loading: false,
      error: false,
      name: "",
      password: "",
      channel: "",
      chatExpire: 0,
      hasAudio: false,
      hasChat: true,
      iat: 0,
      liveName: "",
      logo: "",
      players: [],
      playerSettings: {},
      userId: "",
      userName: "",
      liveId: "",
      mode: "",
      messages: [],
      legacyMode: false,
      isMobile: false,
      hidePassword: false,
      rotations: 0
    };

    const query = new URLSearchParams(window.location.search);
      const presetId = query.get('id')
      if (presetId) this.state.hidePassword = true;
      this.state.password = presetId;

    let token = localStorage.getItem('live-virtualset-token');
    if (token) {
      this.state.loggedIn = true;
      const decoded = jwtDecode(token);
      if (!decoded || !decoded.userName || !decoded.liveId) return this.state.loggedIn = false;
      this.state.name = decoded.userName;
      this.state.liveName = decoded.liveName;
      this.state.password = decoded.liveId;
      if (presetId && decoded.liveId != presetId) return this.logout()
      this.state.loading = true;
    }

  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({
      behavior: "smooth"
    });
  }

  sendMessage(message) {
    let msg = {
      type: "chatMessage",
      message: message
    }
    try {
      if (window.ws) return window.ws.send(JSON.stringify(msg));
      this.startWs();
      setTimeout(() => {
        try {
          window.ws.send(JSON.stringify(msg));
        } catch {}
      }, 2000)
    } catch {}
  }

  componentDidMount() {
    // this will re-enable mobile mode on iphone
    // if (isIOS && isMobileOnly) this.setState({
    //   isMobile: true
    // });
    // if (isIOS && isMobileOnly) this.setState({legacyMode: true});

    if (localStorage.getItem('live-virtualset-token'))
      this.login(true);

    setInterval(() => {
      let msg = {
        type: "authUpdate"
      }
      try {
        if (window.ws) return window.ws.send(JSON.stringify(msg));
        this.startWs();
        setTimeout(() => {
          try {
            window.ws.send(JSON.stringify(msg));
          } catch {}
        }, 2000)
      } catch {}
    }, 8000);

    document.body.style.backgroundColor = this.state.loggedIn ? "#111111" : "#000000";
    // document.body.style.backgroundImage = this.state.loggedIn ? null : "url(/cool-background9.jpg)";
  }


  componentWillUnmount() {
    document.body.style.backgroundColor = null;
  }

  componentDidUpdate() {

    if (this.state.loggedIn !== this.prevLogin) {
      this.prevLogin = this.state.loggedIn;
      document.body.style.backgroundColor = this.state.loggedIn ? "#111111" : "#000000";
      // document.body.style.backgroundImage = this.state.loggedIn ? null : "url(/cool-background9.jpg)";
    }


  }

  keyDown(event) {
    if (event.keyCode == '13') {
      this.login();
    }
  }


  vote() {

  }

  async login(isAuto = false) {
    let browserId = localStorage.getItem('browserId');
    if (!browserId) {
      browserId = genSession();
      localStorage.setItem('browserId', browserId);
    }

    let response = {ok: false};

    try {
      response = await fetch(`${baseUrl}/auth`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: this.state.password.trim(),
          nome: this.state.name.trim(),
          sessione: browserId,
          browser: {
            codeName: navigator.appCodeName,
            name: navigator.vendor,
            version: navigator.appVersion,
            cookies: navigator.cookieEnabled,
            platform: navigator.platform,
            userAgent: navigator.userAgent
          }
        })
      });
    } catch {}

    if (!response.ok) {
      // response.text().then(res => console.log(res));
      localStorage.removeItem('live-virtualset-token');
      this.setState({
        error: isAuto ? false : true,
        // name: "", // should not empty out the name, let's avoid rewriting it every time
        password: "",
        loading: false,
        loggedIn: false
      });
      setTimeout(() => {
        this.setState({
          error: false
        });
        window.location.search = "";
      }, 2200);
      return;
    }
    let data = {};
    try {
      data = await response.json()
    } catch (e) {
      console.log(e.message)
    }
    if (data && data.token)
      localStorage.setItem('live-virtualset-token', data.token);
    const decoded = jwtDecode(data.token);
    this.setState({...decoded, loggedIn: true});

    this.startWs();
  }

  logout(silent = false) {
    localStorage.removeItem('live-virtualset-token');
    if (silent) return this.setState({
      loggedIn: false,
      // name: "", // should not empty out the name, let's avoid rewriting it every time
      password: "",
      loading: false
    });

    this.setState({
      loggedIn: false,
      error: false,
      // name: "", // should not empty out the name, let's avoid rewriting it every time
      password: "",
      loading: false
    });

    window.location.search = "";
  }

  startWs() {
    var self = this;
    if (!localStorage.getItem('live-virtualset-token')) return;
    if (window.ws) {
      try {window.ws.close();}catch {}
    }
    console.log("connecting...");
    window.ws = new WebSocket(
      'wss://client-api.streamset.pro/v2/wss',
      localStorage.getItem('live-virtualset-token')
    );

    window.ws.onmessage = function (event) {
      let obj = {};
      try {
        obj = JSON.parse(event.data);
      } catch {}
      if (!obj || !obj.type) return;
      self.parseWs(obj);
    };

    window.ws.onclose = function (event) {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.log('[close] Connection died');
        setTimeout(() => {
          self.startWs();
        }, 4000);
      }
    };

  }

  parseWs(obj) {
    if (obj.type === "chatUpdate") {
      for (let message of obj.messages) {
        if (message.userId === this.state.userId) message.isMe = true;
        message.time = new Date(message.time);
        message.time = `${calculateDayString(message.time)} ${calcHour(message.time)}`;
      }
      this.setState(Object.assign({}, this.state, {
        messages: obj.messages
      }));
      return;
    }
    if (obj.type === "reloadPage") {
      if (obj.forced) window.location.reload();
      return;
    }

    if (obj.type === "logout") {
      if (obj.forced) this.logout();
      return;
    }

  }

  render() {
    if (!this.state.loggedIn) return Login(this);
    // togliamo la versione mobile
    // if (this.state.isMobile && !this.state.legacyMode) return Mobile(this);
    return <Main {...this} logout={()=>{this.logout();}} sendMessage={(e)=>{this.sendMessage(e);}} scrollToBottom={(e)=>{this.scrollToBottom(e);}} parseWs={(e)=>{this.parseWs(e);}} state={this.state}/>;
  }

}

function pad(s) {
  return (s < 10) ? '0' + s : s;
}

function calcHour(time) {
  return `${pad(time.getHours())}:${pad(time.getMinutes())}`;
}

function calculateDayString(time) {
  const today = new Date().getDate()
  if (today - time.getDate() === 0) return 'Today';
  if (today - time.getDate() === 1) return 'Yesterday';
  return [pad(time.getDate()), pad(time.getMonth() + 1), time.getFullYear()].join('/')

}

function genSession(len = 24) {
  let result = "";
  const asset = "qwertyuioplkjhgfdsazxcvbnmMNBVCXZASDFGHJKLPOIUYTREWQ1234567890";
  for (let i = 0; i < len; i++) result += asset[Math.round(Math.random() * (asset.length - 1))];
  return result;
}



export default App;